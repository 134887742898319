<template>
  <v-container fluid>
    <div class="app">
      <v-row>
        <v-col cols="12">
          <BackButton :handler="backToReports" />
        </v-col>
      </v-row>

      <div class="user-wrapper mt-4">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row class="">
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>
          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Online Sales Settlement Report</v-col>
                  <v-col md="4"> </v-col>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission($modules.reports.payment.slug)
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">From</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">To</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Sales</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Fees</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">VAT</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Net Revenue</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="onlineSettlementReport.length"
                        :set="
                          ((grandTotalSales = 0),
                          (grandTotalFees = 0),
                          (grandTotalVat = 0),
                          (grandTotalRevenue = 0))
                        "
                      >
                        <template
                          v-for="(cash, index) in onlineSettlementReport"
                        >
                          <tr :key="`cash_${index}`" v-if="cash.type == 'data'">
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ cash.timestamp | timeStamp }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ cash.from_date | reportDateformat }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ cash.to_date | reportDateformat }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              :set="(grandTotalSales += cash.sales_amount)"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.sales_amount | toCurrency }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              :set="(grandTotalFees += cash.commission_amount)"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.commission_amount | toCurrency }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              :set="(grandTotalVat += cash.vat_amount)"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.vat_amount | toCurrency }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              :set="(grandTotalRevenue += cash.net_revenue)"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.net_revenue | toCurrency }}
                              </div>
                            </td>
                          </tr>
                        </template>
                        <tr
                          key="total"
                          class="md-table-row grand_total_bg sticky_bottom"
                        >
                          <td class="md-table-cell" colspan="3">
                            <div class="md-table-cell-container text-center">
                              <strong>GRAND TOTAL</strong>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{ grandTotalSales | toCurrency }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{ grandTotalFees | toCurrency }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong> {{ grandTotalVat | toCurrency }}</strong>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{ grandTotalRevenue | toCurrency }}</strong
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="columns.length">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: { BackButton, ExportIcon, SvgIcon },
  data() {
    return {
      onlineSettlementReport: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      containerHeight: 400,
      columnData: [
        { name: "Timestamp", value: "timestamp" },
        { name: "From", value: "from" },
        { name: "To", value: "to" },
        { name: "Sales", value: "sales" },
        { name: "Fees", value: "fees" },
        { name: "VAT", value: "vat" },
        { name: "Net Revenue", value: "net_revenue" },
      ],
      columns: [
        "timestamp",
        "from",
        "to",
        "sales",
        "fees",
        "vat",
        "net_revenue",
      ],
    };
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      if (this.fileName != null)
        this.fileName = "Online-Sales-Settlement-Report-" + this.fileName;
      else this.fileName = "Online-Sales-Settlement-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/online-settlement-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            // this.onlineSettlementReport = data;
            const onlineSettlement = data;
            this.onlineSettlementReport = [];
            let i = 0;
            onlineSettlement.forEach((log, index) => {
              if (i == 0) {
                this.onlineSettlementReport.push({
                  date: log.date,
                  type: "date",
                });
                i++;
              } else if (
                i > 0 &&
                onlineSettlement[index - 1] &&
                onlineSettlement[index - 1].date != onlineSettlement[index].date
              ) {
                this.onlineSettlementReport.push({
                  date: log.date,
                  type: "date",
                });
                i++;
              }
              if (i > 0) {
                log.type = "data";
                this.onlineSettlementReport.push(log);
              }

              // i++;
            });
            // console.log(this.onlineSettlementReport);
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "online-sales-settlement-report-pdf";
      } else if (type == "excel") {
        link = "online-sales-settlement-report-excel";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8; /* Set color opacity to 10% */
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
</style>
